<template lang="pug">
	.d-flex.flex-column.overflow-hidden.mb-3.border-rounded.project-short(:class="[contentClass, {'dashboard-row': !expand && (contentClass === 'project-short--dashboard' || contentClass === 'project-partner-short--dashboard') && isMobile}]")
		img.w-100.flex-shrink-0(v-if='item.image' :src='item.image.contentUrl' :alt='item.title')
		.d-flex.flex-column.project-short__info
			h3.mb-2 {{ item.title }}
			.project-short__text-wrap(:class="{'expand-text': expand}")
				p.b3.m-0.project-short__text
					span(ref="paymentText" v-html="description")
			.d-flex.mt-3.mt-md-2.project-short__inner
				.btn.btn-expand.mr-10(v-if="paymentTextHeight > 88 || ((contentClass === 'project-short--dashboard' || contentClass === 'project-partner-short--dashboard') && isMobile)" @click="doExpand" :class="{'rotate': expand}") {{ !expand ? $t('forms.expand') : $t('forms.collapse') }}
				.d-flex
					i.fontello-btn.fontello-btn-primary.fontello-icon-setting.project-short__btn(v-if="contentClass == 'project-short--dashboard'" @click="$router.push({name: 'project_settings', params: {id: item.id}, query: {tab: 1, plan: item.id}})")
					a.ml-10.text-decoration-none.project-short__btn(v-if="!isMobile && (contentClass === 'project-short--dashboard') && item && item.somePlansHasResourceActive() && item.isActive() && item.hasPaymentSystem() && item.canSeeMenuItems() && item.canSeeCover()" :href='paymentLinkDefault' target='_blank')
						i.d-block.fontello-btn.fontello-btn-primary.fontello-icon-link-1
					//- i.fontello-btn.fontello-btn-primary.fontello-icon-printer.ml-10.project-short__btn
					i.fontello-btn.fontello-btn-primary.fontello-icon-scan-barcode-1.ml-10.project-short__btn(v-if="!isMobile && contentClass == 'project-short--dashboard' && item && item.somePlansHasResourceActive() && item.isActive() && item.hasPaymentSystem() && item.canSeeMenuItems() && item.canSeeCover()" :href='paymentLinkDefault' target='_blank' @click='openModalQRCode()')

					template(v-if="contentClass == 'project-partner-short--dashboard' && item.isActive() && !merchantPartnerItem.isArchived && !isMobile")
						a.ml-10.text-decoration-none.project-short__btn(:href='paymentPartnerLinkDefault' target='_blank')
							i.d-block.fontello-btn.fontello-btn-primary.fontello-icon-link-1
						i.fontello-btn.fontello-btn-primary.fontello-icon-scan-barcode-1.ml-10.project-short__btn(:href='paymentLinkDefault' target='_blank' @click='openModalQRCode()')

</template>

<script>
export default {
    name: 'ProjectCardShort',
    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
        contentClass: {
            type: String,
            default: '',
        },
        merchantPartnerItem: {
            type: Object,
        },
    },
    data: () => ({
        expand: false,
        paymentTextHeight: null,
    }),
    computed: {
        paymentLink() {
            return this.item ? `${this.paymentLinkDomain}${this.item.hashIdCustom}` : '';
        },
        paymentLinkDefault() {
            if (this.item.hashIdCustom != this.item.hashId) return this.paymentLink;

            return this.item ? `${this.paymentLinkDomain}${this.item.hashId}` : '';
        },
        paymentPartnerLinkDefault() {
            return `${window.location.protocol}//${window.location.host.replace('pp.', '')}/${this.merchantPartnerItem.hashId}`;
        },
        paymentLinkDomain() {
            return `${window.location.protocol}//${window.location.host.replace('merchant.', '')}/`;
        },
        description() {
            if (!this.item || !this.item.description) return '';

            let desc = this.item.description.replace(
                /(https?\:\/\/[^\s]*)/gi,
                '<a class="text-primary" target="_blank" href="$1">$1</a>'
            );

            return desc;
        },
    },
    mounted() {
        this.$nextTick(() => {
            if (this.$refs.paymentText) this.paymentTextHeight = this.$refs.paymentText.offsetHeight;
        });

        window.addEventListener('resize', this.resizeHandler);
    },
    methods: {
        doExpand() {
            this.expand = !this.expand;
            this.$emit('onExpanded', this.expand);
        },
        resizeHandler() {
            if (this.$refs.paymentText) this.paymentTextHeight = this.$refs.paymentText.offsetHeight;
        },
        openModalQRCode() {
            this.$bvModal.show(`show-qr-code`);
        },
    },
    watch: {
        description() {
            if (this.$refs.paymentText) this.paymentTextHeight = this.$refs.paymentText.offsetHeight;
        },
    },
};
</script>

<style scoped lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.project-short {
    height: max-content;
    box-shadow: var(--card-box-shadow);
    background: var(--foreground-color);

    &__info {
        padding: 16px;

        @include respond-below(xs) {
            width: 100%;
        }
    }

    &__text-wrap {
        max-height: 88px;
        overflow: hidden;
        transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);

        .project-short__text {
            word-wrap: break-word;
            white-space: break-spaces;
        }

        &.expand-text {
            max-height: 200rem;
            transition: max-height 0.3s cubic-bezier(0.9, 0, 0.8, 0.2);

            .project-short__text {
                animation: open 0.1s linear 0s forwards;
            }
        }
    }

    &__text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        animation: close 0.1s linear 0.1s forwards;
    }

    @keyframes open {
        from {
            -webkit-line-clamp: 4;
        }
        to {
            -webkit-line-clamp: initial;
        }
    }

    @keyframes close {
        from {
            -webkit-line-clamp: initial;
        }
        to {
            -webkit-line-clamp: 4;
        }
    }

    .fontello-btn {
        width: 30px;
        height: 30px;
        padding: 7px;
    }

    &.dashboard-row {
        @include respond-below(sm) {
            flex-direction: row !important;
            padding: 15px 17px;

            img {
                width: 95px !important;
                height: 53px;
                object-fit: cover;
                border-radius: 6px;
                margin-right: 10px;
            }

            .project-short__info {
                padding: 0;
            }

            h3 {
                font-size: 14px !important;
                line-height: 22px !important;
                margin-bottom: 5px !important;
            }

            .project-short__text-wrap {
                display: none;
            }

            .project-short__inner {
                margin: 0 !important;
            }
        }
    }

    &__inner {
        .fontello-btn {
            padding: 0;
            display: flex !important;
            justify-content: center;
            align-items: center;

            &:before {
                font-size: 17px;
                width: 18px;
            }
        }
    }

    &__btn {
        @include respond-below(sm) {
            width: 45px !important;

            i {
                width: 100% !important;
            }
        }
    }

    &--dashboard {
        background-color: var(--foreground-color);
        margin-bottom: 20px !important;

        .btn-expand {
            font-size: 12px;
            padding: 7px 29px 7px 15px;

            @include respond-below(xs) {
                margin-right: 5px !important;
            }

            &::before {
                top: 9px;
                right: 13px;
                width: 12px;
                height: 12px;
            }
        }
    }
}
</style>
